<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>About Us</li><span class="arrow">></span>
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>
                   <p>
                     <strong> Vision Statement</strong><br>

The vision of Yangchenphug is to be the center of academic excellence in providing Senior Secondary Education with a pleasant ambience and learning atmosphere conducive to the total personality development of the learners. 

<br><br>
<strong>Mission Statement</strong> <br>


The fundamental purpose of Yangchenphug is to engender productive citizens possessing national, moral, social and personal values, with academic excellence coupled with other life skills,
 who would be competitive and useful in contributing towards the attainment of Gross National Happiness through the provision of high quality educational facilities.
                  <br>
                  <img src="@/assets/img/value.png" alt="">
                  </p>
              </div>
              
          <div class="sidebar">
              <AboutUsQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import AboutUsQuickLinks from '../../components/page/AboutUsQuickLinks.vue';
import Footer from "../../components/page/Footer.vue";




export default {
    name: 'VisionMission',    
    components: { TopBar, Header, NavBar, RecentPosts, AboutUsQuickLinks, Footer },
    setup(){
        const title= ref('Vision & Mission')
        return {title}
    }
    
  
}
</script>

<style scoped>

 section .container .page .page-content img{
    width: 50%;
    display: block;
    margin: auto;
    padding: 1rem 0;
   
}

</style>